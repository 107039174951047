import React from "react";
import { Router, Route, Switch } from "react-router-dom";
import history from "./utils/history.js"

function App() {
  return (
    <div>
        <Router history={history}>
          <Switch>
          <Route key="/" path="/" component={() => { 
              window.location.href = 'https://sites.google.com/carmodity.ca/dealerflow/screensaver'; 
              return null}} />
          </Switch>
        </Router>
    </div>
  );
}

export default App;

